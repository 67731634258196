import React from 'react';

export const Hamburger = (props: React.SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 100 80"
      width="40"
      height="40"
      fill="currentColor"
      {...props}>
      <rect width="100" height="20"></rect>
      <rect y="30" width="100" height="20"></rect>
      <rect y="60" width="100" height="20"></rect>
    </svg>
  );
};
