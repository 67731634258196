import { cs } from '@/utils/string';
import { LabelHTMLAttributes } from 'react';

export const Label = (props: LabelHTMLAttributes<HTMLLabelElement>) => {
  return (
    <label
      {...props}
      className={cs('inline-block mb-2', props.className)}></label>
  );
};
