import React from 'react';
import { Formik, ErrorMessage, Form } from 'formik';
import { Input, Label, Button } from '@/components/Form';
import { entrypointCreateValidator } from '~/EntrypointCreate/entrypoint-create-validator';
import { createEntrypoint } from '~/EntrypointCreate/entrypoint-create-api';
import { toast } from 'react-toastify';
import { NavigateFunction, useNavigate } from 'react-router-dom';

export const EntrypointCreate: React.FC = () => {
  const navigate: NavigateFunction = useNavigate();

  const initialValues = {
    redirectUrl: '',
    uses: '',
    expiry: '',
    name: ''
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const todaySecond = Math.ceil(new Date().getTime() / 1000);
      const expirySecond = Math.ceil(new Date(values.expiry).getTime() / 1000);
      setSubmitting(true);
      await createEntrypoint({
        ...values,
        expiry: expirySecond - todaySecond
      });
      navigate('/', { replace: true });
      toast.success('Create new entrypoint successfully');
      resetForm();
    } catch (e: any) {
      toast.error(e?.message || 'There was an error creating new entrypoint');
      //TODO
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="block p-6 rounded-lg max-w-sm shadow-lg bg-white">
      <Formik
        initialValues={initialValues}
        validationSchema={entrypointCreateValidator}
        onSubmit={onSubmit}>
        {({ values, handleChange, isSubmitting }) => (
          <Form>
            <div className="form-group mb-4">
              <Label htmlFor="redirectUrl" className="form-label text-grey-700">
                Redirect URL <span className="text-danger">(*)</span>
              </Label>
              <Input
                onChange={handleChange}
                value={values.redirectUrl}
                id="redirectUrl"
                name="redirectUrl"
                placeholder="Example: https://example.com/redirect"
              />
              <ErrorMessage
                component="a"
                className="text-danger"
                name="redirectUrl"
              />
            </div>

            <div className="form-group mb-4">
              <Label htmlFor="uses" className="form-label text-grey-700">
                Uses <span className="text-danger">(*)</span>
              </Label>
              <Input
                value={values.uses}
                onChange={handleChange}
                name="uses"
                type="number"
                id="uses"
                placeholder="Example: 100"
              />
              <ErrorMessage component="a" className="text-danger" name="uses" />
            </div>

            <div className="form-group mb-4">
              <Label htmlFor="expiry" className="form-label text-grey-700">
                Expiry time <span className="text-danger">(*)</span>
              </Label>
              <Input
                value={values.expiry}
                onChange={handleChange}
                name="expiry"
                type="datetime-local"
                id="expiry"
              />
              <ErrorMessage
                component="a"
                className="text-danger"
                name="expiry"
              />
            </div>

            <div className="form-group mb-6">
              <Label htmlFor="name" className="form-label text-grey-700">
                Entrypoint name
              </Label>
              <Input
                value={values.name}
                onChange={handleChange}
                placeholder="Example: Entrypoint name..."
                name="name"
                type="text"
                id="name"
              />
              <ErrorMessage component="a" className="text-danger" name="name" />
            </div>
            <div className="form-group">
              <Button
                className="mr-3"
                type="button"
                disabled={isSubmitting}
                onClick={(): void => {
                  navigate('/', { replace: true });
                }}>
                Cancel
              </Button>
              <Button disabled={isSubmitting} theme="primary" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
