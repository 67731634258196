export function mergeStrings(...strings: (string | undefined)[]) {
  return strings.join(' ').trim();
}

/**
 * Alias name of mergeStrings, use to merge classNames.
 */
export function cs(...args: Parameters<typeof mergeStrings>) {
  return mergeStrings(...args);
}
