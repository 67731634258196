import backend from '../../common/api/backend';
import { reactAppConfig } from '@/config/react-app-config';
import { FetchEntrypointsResponse } from '~/Entrypoints/entrypoints-types';

export const getEntrypoints = (): Promise<FetchEntrypointsResponse> => {
  return backend
    .get(`${reactAppConfig.backendEnv}/entrypoints`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Failed to fetch the entrypoints:', JSON.stringify(error));
      return error.status;
    });
};

export const deleteEntrypoint = (
  id: string
): Promise<FetchEntrypointsResponse> => {
  return backend
    .delete(`/test/entrypoints/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Failed to delete the entrypoint:', JSON.stringify(error));
      throw error;
    });
};
