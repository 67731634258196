import backend from '../../common/api/backend';

import {
  EntrypointCreateData,
  FetchEntrypointCreateResponse
} from '~/EntrypointCreate/entrypoint-create-types';

export const createEntrypoint = (
  data: EntrypointCreateData
): Promise<FetchEntrypointCreateResponse> => {
  return backend
    .post(`/test/entrypoints`, data)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Failed to create the entrypoint:', JSON.stringify(error));
      throw error;
    });
};
