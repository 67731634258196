import React from 'react';
import {
  ClipboardDocumentListIcon,
  TrashIcon
} from '@heroicons/react/20/solid';
import {
  CardContent,
  CardGrid,
  IconAndAction
} from '@/components/CardGrid/CardGrid';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IModal, Modal } from '@/components/Modal';
export interface EntrypointsCardGridProps {
  gridContent: EntrypointCardContent[];
  // eslint-disable-next-line no-unused-vars
  onDeleteEntrypoint: (entrypointId: string) => Promise<void>;
}

export interface EntrypointCardContent {
  title: string;
  url: string;
  id: string;
}

export const EntrypointCardGrid = (
  entrypointsCardGridProps: EntrypointsCardGridProps
) => {
  const [deleteModal, setDeleteModal] = React.useState<IModal>({
    showModal: false,
    title: 'Delete Entrypoint?',
    description: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    action: async () => {}
  });

  const entrypointCardGridIconsAndActions: IconAndAction[] = [
    {
      title: 'Delete',
      icon: TrashIcon,
      action: (cardContent: CardContent) => {
        setDeleteModal({
          ...deleteModal,
          showModal: true,
          description: `Are you sure that you want to delete entrypoint ${cardContent.title}?`,
          action: async () => {
            try {
              await entrypointsCardGridProps.onDeleteEntrypoint(
                cardContent.id || ''
              );
              toast.success('Deleted!');
            } catch (e: any) {
              toast.error(
                e?.message || 'There was an error deleting entrypoint'
              );
            } finally {
              setDeleteModal({
                ...deleteModal,
                showModal: false
              });
            }
          }
        });
      }
    },
    {
      title: 'Copy link',
      icon: ClipboardDocumentListIcon,
      action: (cardContent: CardContent) => {
        cardContent.url
          ? navigator.clipboard.writeText(
              cardContent.url ?? 'Entrypoint missing URL!'
            )
          : toast.warning('Entrypoint missing URL!');
        toast.info('Copied!', {
          position: 'bottom-left'
        });
      }
    }
  ];

  const gridContent: CardContent[] = [
    { addNew: true, url: '/entrypoints/create' }
  ];
  gridContent.push(...entrypointsCardGridProps.gridContent);

  const navigate = useNavigate();
  return (
    <>
      <Modal modal={deleteModal} setModal={setDeleteModal} />
      <CardGrid
        gridContent={gridContent}
        iconsAndActions={entrypointCardGridIconsAndActions}
        onCardClick={(cardContent: CardContent) =>
          navigate({
            pathname: '/sessions',
            search: `?entrypointId=${cardContent.id}`
          })
        }
      />
    </>
  );
};
