import React, { useCallback, useEffect, useState } from 'react';
import { getSessions, deleteSession } from '~/Sessions/sessions-api';
import { SessionData, FetchSessionsResponse } from '~/Sessions/sessions-types';
import {
  SessionCardContent,
  SessionCardGrid
} from '@/components/CardGrid/SessionCardGrid';
import { useLocation } from 'react-router-dom';
import { userProfileSelector } from '@/store/user';
import { useAppSelector } from '@/store';
import { Button } from '@/components/Form';
import { ArrowPathIcon } from '@heroicons/react/20/solid';

export const Sessions: React.FC = () => {
  const [sessions, setSessions] = useState<FetchSessionsResponse | undefined>(
    undefined
  );
  const [reloadState, setReloadState] = useState<string>('');
  const [sessionsAdaptedForCardGrid, setSessionsAdaptedForCardGrid] = useState<
    SessionCardContent[] | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);

  const search = useLocation().search;
  const userProfile = useAppSelector(userProfileSelector);
  const entrypointId = new URLSearchParams(search).get('entrypointId');

  const fetchSessions = useCallback(async () => {
    if (entrypointId) {
      setLoading(true);
      const sessionData = await getSessions(entrypointId);

      // TODO: implement react-query library or simply write a custom hook to reduce boilerplate code when calling API (loading, error, data,...)
      setLoading(false);
      setSessions(sessionData);
    }
  }, [entrypointId]);

  useEffect(() => {
    fetchSessions();
  }, [reloadState]);

  useEffect(() => {
    if (sessions && sessions.Items.length > 0) {
      setSessionsAdaptedForCardGrid(
        mapFetchedSessionsDataFetchedToCardGridCompatibleData(sessions.Items)
      );
    }
  }, [sessions]);

  const mapFetchedSessionsDataFetchedToCardGridCompatibleData = (
    sessions: SessionData[]
  ): SessionCardContent[] =>
    sessions.map((session) => {
      return {
        id: session.SessionId,
        title: session.ReportedName ?? 'N/A',
        subtitle: session.ClosedAt,
        inProgress: !session.ClosedAt,
        closedAt: session.ClosedAt
          ? new Date(session.ClosedAt.replace('Z', ''))
          : undefined
      };
    });

  const onDeleteSession = async (sessionId: string) => {
    try {
      await deleteSession(entrypointId || '', sessionId);
      setReloadState(sessionId);
    } catch (e) {
      console.log('Delete session errors');
      throw e;
    }
  };

  return (
    <>
      <div className="text-right">
        <Button
          theme="primary"
          className="mb-4 inline-flex items-center"
          onClick={fetchSessions}>
          <ArrowPathIcon className="mr-2" width={20} height={20} />
          <span>Refresh</span>
        </Button>
      </div>

      {loading ? (
        <div>Loading...</div>
      ) : (
        sessionsAdaptedForCardGrid &&
        userProfile &&
        entrypointId && (
          <SessionCardGrid
            gridContent={sessionsAdaptedForCardGrid}
            parentId={entrypointId}
            onDeleteSession={onDeleteSession}
          />
        )
      )}
    </>
  );
};
