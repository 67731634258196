import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getEntrypoints,
  deleteEntrypoint
} from '~/Entrypoints/entrypoints-api';
import {
  EntrypointData,
  FetchEntrypointsResponse
} from '~/Entrypoints/entrypoints-types';
import {
  EntrypointCardContent,
  EntrypointCardGrid
} from '@/components/CardGrid/EntrypointCardGrid';

export const Entrypoints: React.FC = () => {
  const navigate = useNavigate();
  const [entrypoints, setEntrypoints] = useState<
    FetchEntrypointsResponse | undefined
  >(undefined);
  const [reloadState, setReloadState] = useState<string>('1');
  const [entrypointsAdaptedForCardGrid, setEntrypointsAdaptedForCardGrid] =
    useState<EntrypointCardContent[] | undefined>(undefined);

  useEffect(() => {
    return navigate('/');
  }, []);

  useEffect(() => {
    const fetchEntrypoints = async () => {
      const entrypointData = await getEntrypoints();
      setEntrypoints(entrypointData);
    };
    fetchEntrypoints();
  }, [reloadState]);

  useEffect(() => {
    if (entrypoints && entrypoints.Items.length > 0) {
      setEntrypointsAdaptedForCardGrid(
        mapFetchedEntrypointsDataFetchedToCardGridCompatibleData(
          entrypoints.Items
        )
      );
    }
  }, [entrypoints]);

  const mapFetchedEntrypointsDataFetchedToCardGridCompatibleData = (
    entrypoints: EntrypointData[]
  ): EntrypointCardContent[] =>
    entrypoints.map((entrypoint) => {
      return {
        title:
          entrypoint.ReportMetaData?.name ??
          entrypoint.EntrypointId.substring(0, 8),
        url: entrypoint.Url,
        id: entrypoint.EntrypointId
      };
    });

  const onDeleteEntrypoint = async (entrypointId: string) => {
    try {
      await deleteEntrypoint(entrypointId);
      setReloadState(entrypointId);
    } catch (e) {
      console.log('Delete entrypoint errors');
      throw e;
    }
  };

  return (
    <div>
      {entrypointsAdaptedForCardGrid && (
        <EntrypointCardGrid
          gridContent={entrypointsAdaptedForCardGrid}
          onDeleteEntrypoint={onDeleteEntrypoint}
        />
      )}
    </div>
  );
};
