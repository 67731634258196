import { PlusIcon } from '@heroicons/react/20/solid';
import { NavigateFunction, useNavigate } from 'react-router-dom';

export interface CardGridProps<CardContentType> {
  gridContent: CardContentType[];
  iconsAndActions?: IconAndAction<CardContentType>[];
  // eslint-disable-next-line no-unused-vars
  onCardClick?: (cardContent: CardContentType) => void;
}

export interface CardContent {
  id?: string;
  title?: string;
  subtitle?: string;
  url?: string;
  inProgress?: boolean;
  addNew?: boolean;
}

export interface IconAndAction<CardContentType = CardContent> {
  title: string;
  icon: any;
  // eslint-disable-next-line no-unused-vars
  action?: (cardContent: CardContentType) => void | Promise<void>;
}

export const CardGrid = <T extends CardContent = CardContent>(
  cardGridProps: CardGridProps<T>
) => {
  const navigate: NavigateFunction = useNavigate();
  return (
    <ul
      role="list"
      className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-5">
      {cardGridProps.gridContent.map((cardContent, key) =>
        !cardContent.inProgress && !cardContent.addNew ? (
          <li
            key={key}
            className="col-span-1 cursor-pointer rounded-lg bg-black/[.02] shadow divide-y divide-gray-200">
            <div
              className="flex w-full items-center justify-between space-x-6 p-6"
              onClick={() =>
                cardGridProps.onCardClick
                  ? cardGridProps.onCardClick(cardContent)
                  : undefined
              }>
              <div className="flex-1 truncate">
                <div
                  className={`${
                    cardContent.subtitle ? 'flex' : 'text-center'
                  } items-center space-x-3`}>
                  <h3
                    className={`truncate ${
                      cardContent.subtitle ? 'text-sm' : 'text-xl'
                    } font-medium text-gray-900`}>
                    {cardContent.title}
                  </h3>
                </div>
                <p className="mt-1 truncate text-sm text-gray-500">
                  {cardContent.subtitle}
                </p>
              </div>
            </div>
            <div>
              <div className="-mt-px flex divide-x divide-gray-200">
                {cardGridProps.iconsAndActions?.map((iconAndAction) => {
                  const Icon = iconAndAction.icon;
                  return (
                    <div
                      className="flex w-0 flex-1"
                      key={`icon-action-${iconAndAction.title}`}>
                      <a
                        className={`relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium ${
                          iconAndAction.action
                            ? 'text-gray-700 hover:text-gray-500 cursor-pointer'
                            : 'text-gray-700/[.3] cursor-not-allowed'
                        }`}
                        onClick={() =>
                          iconAndAction.action
                            ? iconAndAction.action(cardContent)
                            : undefined
                        }>
                        <Icon
                          className={`h-5 w-5 ${
                            iconAndAction.action
                              ? 'text-gray-400'
                              : 'text-gray-400/[.3]'
                          }`}
                          aria-hidden="true"
                        />
                        <span className="ml-3">{iconAndAction.title}</span>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </li>
        ) : (
          <li
            key={`card${cardContent.title}`}
            className="col-span-1 rounded-lg bg-black/[.02] shadow outline md:outline-dashed flex justify-center">
            <div className="flex items-center space-x-6 p-6">
              {cardContent.inProgress ? (
                <h3 className="truncate text-sm font-medium text-gray-900">
                  {'Running...'}
                </h3>
              ) : (
                <PlusIcon
                  className="h-20 w-20 text-gray-600 cursor-pointer"
                  aria-hidden="true"
                  onClick={() =>
                    navigate(cardContent.url || '/', { replace: true })
                  }
                />
              )}
            </div>
          </li>
        )
      )}
    </ul>
  );
};
