const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_AWS_HOSTED_UI_DOMAIN,
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin'
    ],
    redirectSignIn: process.env.REACT_APP_AWS_COGNITO_CALLBACK_URL,
    redirectSignOut: process.env.REACT_APP_AWS_COGNITO_CALLBACK_URL,
    responseType: 'token'
  },
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
    signUpVerificationMethod: 'link',
    mandatorySignIn: false,
    cookieStorage: {
      domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
      path: '/',
      expires: 365,
      secure: true
    },
    redirectSignIn: process.env.REACT_APP_AWS_COGNITO_CALLBACK_URL,
    redirectSignOut: process.env.REACT_APP_AWS_COGNITO_CALLBACK_URL
  }
};

export default amplifyConfig;
