import { useState } from 'react';
import { ArrowDownTrayIcon, TrashIcon } from '@heroicons/react/20/solid';
import { CardGrid, IconAndAction } from '@/components/CardGrid/CardGrid';
import { getReport } from '~/Sessions/sessions-api';
import { toast } from 'react-toastify';
import { generateDocumentDefinitionFromJSON } from '@/utils/pdf';
import format from 'date-fns/format';
import { IModal, Modal } from '@/components/Modal';

import pdfMake, { createPdf } from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export interface SessionsCardGridProps {
  gridContent: SessionCardContent[];
  parentId: string;
  // eslint-disable-next-line no-unused-vars
  onDeleteSession: (sessionId: string) => Promise<void>;
}

export interface SessionCardContent {
  id: string;
  title: string;
  subtitle: string;
  inProgress: boolean;
  closedAt: Date | undefined;
}

export const SessionCardGrid = (
  sessionsCardGridProps: SessionsCardGridProps
) => {
  const { parentId: entrypointId, onDeleteSession } = sessionsCardGridProps;

  const [deleteModal, setDeleteModal] = useState<IModal>({
    showModal: false,
    title: 'Delete Session?',
    description: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    action: async () => {}
  });

  const sessionCardGridIconsAndActions: IconAndAction<SessionCardContent>[] = [
    {
      title: 'Delete',
      icon: TrashIcon,
      action: (cardContent) => {
        setDeleteModal({
          ...deleteModal,
          showModal: true,
          description: `Are you sure that you want to delete session ${cardContent.title}?`,
          action: async () => {
            try {
              await onDeleteSession(cardContent.id || '');
              toast.success('Deleted!');
            } catch (e: any) {
              toast.error(e?.message || 'There was an error deleting session');
            } finally {
              setDeleteModal({
                ...deleteModal,
                showModal: false
              });
            }
          }
        });
      }
    },
    {
      title: 'Download',
      icon: ArrowDownTrayIcon,
      action: (cardContent) => {
        const getReportData = async () => {
          try {
            const reportData = await getReport(entrypointId, cardContent.id!);

            if (reportData.Items.length === 0) {
              toast.warning('There are no reports available for this session');
            } else {
              const dateStr = cardContent.closedAt
                ? format(cardContent.closedAt, 'yyyy-MM-dd-HH-ii')
                : 'UnknownTime';
              const documentTitle = `${dateStr}-${cardContent.title}.pdf`;

              createPdf(
                generateDocumentDefinitionFromJSON(
                  reportData.Items.map((report) => report.Payload),
                  {
                    info: {
                      title: documentTitle
                    }
                  }
                ),
                {}
              ).download(documentTitle);
            }
          } catch (error) {
            console.error(error);
            toast.error('There was an error fetching your reports');
          }
        };
        getReportData();
      }
    }
  ];

  return (
    <>
      <Modal modal={deleteModal} setModal={setDeleteModal} />
      <CardGrid<SessionCardContent>
        gridContent={sessionsCardGridProps.gridContent}
        iconsAndActions={sessionCardGridIconsAndActions}
      />
    </>
  );
};
