import { Outlet } from 'react-router-dom';

const LoginLayout = () => {
  return (
    <div className="h-[100vh] overflow-auto flex items-center bg-gray">
      <div className="container">
        <Outlet />
      </div>
    </div>
  );
};

export default LoginLayout;
