import { Outlet, OutletProps, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/store';
import { getUserProfile } from '@/store/user';
import { useEffect, useState } from 'react';
import { Header, Breadcrumb } from '@/containers';

const DefaultLayout = (props: OutletProps) => {
  const dp = useAppDispatch();
  const navigate = useNavigate();
  const [isProfileLoaded, setProfileLoadedState] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { cognitoUser, token } = await dp(getUserProfile()).unwrap();

        if (!cognitoUser || !token) return navigate('/login');

        setProfileLoadedState(true);
      } catch (e) {
        return navigate('/login');
      }
    })();
  }, []);

  if (!isProfileLoaded) return null;

  return (
    <>
      <Header />
      <Breadcrumb />

      <div className="container py-5">
        <Outlet {...props}></Outlet>
      </div>
    </>
  );
};

export default DefaultLayout;
