import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserInfo, UserState } from './types';
import { Auth } from 'aws-amplify';
import backend from '@/api/backend';

const initialState: UserState = {};

export const getUserProfile = createAsyncThunk(
  'user/getUserProfile',
  async () => {
    const cognitoUser: UserInfo = await Auth.currentUserInfo();
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    return { cognitoUser, token };
  }
);

export const signOut = createAsyncThunk('user/signOut', async () => {
  const result = await Auth.signOut();
  return result;
});

const setAuthState = (
  state: UserState,
  data: { payload: { cognitoUser?: UserInfo; token: string } }
) => {
  const {
    payload: { cognitoUser, token }
  } = data;

  if (!cognitoUser || !token) return;

  state.profile = cognitoUser;
  state.token = token;

  backend.defaults.headers.common['Authorization'] = `${token}`;
};

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthState
  },
  extraReducers(builder) {
    builder
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        setAuthState(state, { payload });
      })
      .addCase(signOut.fulfilled, (state) => {
        state.profile = undefined;
        state.token = undefined;
        delete backend.defaults.headers.common['Authorization'];
      });
  }
});
