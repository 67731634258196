import { Entrypoints, Sessions, EntrypointCreate } from '../../pages';
import LoginLayout from '@/layout/Login';
import { Login } from '~/Login';
import DefaultLayout from '../layout/Default';
import type { AppRoute } from '@/types';
import { HomeIcon } from '@heroicons/react/20/solid';

export const routes: AppRoute[] = [
  {
    path: '/',
    element: <DefaultLayout />,
    key: 'AppLayout',
    breadcrumb: () => (
      <>
        <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
        <span className="sr-only">Home</span>
      </>
    ),
    routes: [
      {
        index: true,
        key: 'Index',
        element: <Entrypoints />
      },
      {
        path: '/sessions',
        key: 'Sessions',
        element: <Sessions />
      },
      {
        path: '/entrypoints',
        key: 'Entrypoints',
        element: <Entrypoints />
      },
      {
        path: '/entrypoints/create',
        key: 'EntrypointCreate',
        element: <EntrypointCreate />
      }
    ]
  },
  {
    key: 'LoginLayout',
    path: '/login',
    element: <LoginLayout />,
    routes: [
      {
        index: true,
        key: 'Login',
        element: <Login />
      }
    ]
  }
];
