export function isPlainObject(obj: unknown) {
  return (
    typeof obj === 'object' && // separate from primitives
    obj !== null && // is obvious
    obj.constructor === Object && // separate instances (Array, DOM, ...)
    Object.prototype.toString.call(obj) === '[object Object]'
  ); // separate build-in like Math
}

export function replacer(match, pIndent, pKey, pVal, pEnd) {
  const key = '<span style="color:brown;">';
  const val = '<span style="color:navy;">';
  const str = '<span style="color:olive;">';
  let r = pIndent || '';
  if (pKey) r = r + key + pKey.replace(/[": ]/g, '') + '</span>: ';
  if (pVal) r = r + (pVal[0] == '"' ? str : val) + pVal + '</span>';
  return r + (pEnd || '');
}

export function prettyPrint(obj) {
  const jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/gm;
  const preStyle = `
    display: block;
    white-space: pre;
    padding: 10px 20px;
    margin: 20px;
  `;
  const codeStyle = `
    display: block;
    white-space: pre;
  `;
  return `<pre style="${preStyle}"><code style="${codeStyle}">${JSON.stringify(
    obj,
    null,
    3
  )
    .replace(/&/g, '&amp;')
    .replace(/\\"/g, '&quot;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(jsonLine, replacer)}</code></pre>`;
}
