import { logoBase64 } from '@/assets/image/logo';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import htmlToPdfmake from 'html-to-pdfmake';
import { isPlainObject, prettyPrint } from './object';

const MONEY_FIELDS = [
  'amount',
  'tax',
  'sumToPay',
  'total',
  'penalties',
  'adjustments',
  'interest',
  'surcharges',
  'payments',
  'profits',
  'NIpayed',
  'totalNIpayed',
  'totalTaxableIncome',
  'totalIncomeTaxPayed',
  'taxableIncome',
  'incomeTaxPayed'
];

function camelCaseToFirstLetterUpperCase(str: string) {
  return capitalizeWords(str.replace(/([a-z])([A-Z])/, '$1 $2'));
}

function capitalizeWords(string: string) {
  const stringSplittedBySpace = string.split(' ');
  return stringSplittedBySpace
    .map((word, index) => {
      return (
        word.charAt(0).toUpperCase() +
        word.slice(1).toLowerCase() +
        (index !== stringSplittedBySpace.length - 1 ? ' ' : '')
      );
    })
    .join('');
}

function generatePaymentTables(payments: any[]) {
  let paymentTablesContent: any[] = [];
  payments.forEach((payment, index) => {
    const paymentTableContent = [
      { text: `PAYMENT NR.${index + 1}\n\n`, style: 'subheader2' },
      genericTable([payment])
    ];
    paymentTablesContent = [...paymentTablesContent, paymentTableContent];
  });
  const generatePaymentsTwoDimensionalArray = () => {
    const numberOfColumns = 3;
    const numberOfRows = Math.ceil(
      paymentTablesContent.length / numberOfColumns
    );
    const paymentsTwoDimensionalArray = [...Array(numberOfRows)].map(() =>
      Array(3).fill('')
    );
    for (let rowIndex = 0; rowIndex < numberOfRows; rowIndex++) {
      for (let columnIndex = 0; columnIndex < numberOfColumns; columnIndex++) {
        const paymentsIndex = rowIndex * numberOfColumns + columnIndex;
        if (paymentsIndex < paymentTablesContent.length) {
          paymentsTwoDimensionalArray[rowIndex][columnIndex] =
            paymentTablesContent[paymentsIndex];
        } else {
          paymentsTwoDimensionalArray[rowIndex][columnIndex] = [];
        }
      }
    }

    return paymentsTwoDimensionalArray;
  };
  return {
    layout: 'noBorders',
    style: 'tableExample',
    table: {
      heights: 150,
      widths: ['*', '*', '*'],
      body: generatePaymentsTwoDimensionalArray()
    }
  };
}

export const generateDocumentDefinitionFromJSON = (
  reports: any,
  extraDocumentDefinitionProps?: Partial<TDocumentDefinitions>
) => {
  const documentDefinition: TDocumentDefinitions = {
    pageSize: 'A3',
    pageOrientation: 'landscape',
    header: {
      columns: [
        {
          text: ''
        },
        {
          image: logoBase64,
          width: 80
        }
      ],
      style: 'header'
    },
    content: [
      reports
        .sort(function (reportOne, reportTwo) {
          if (!reportOne.tax_year) {
            return -1;
          } else if (!reportTwo.tax_year) {
            return 1;
          }
          if (
            Number.parseInt(reportOne.tax_year.substring(0, 4)) -
              Number.parseInt(reportTwo.tax_year.substring(0, 4)) >
            0
          )
            return -1;
          return 1;
        })
        .map((_report: any, index: number) => {
          const report = JSON.parse(JSON.stringify(_report));
          currencyFormatObject(report);
          const isPersonalReport = !report.tax_year && report.name;

          return [
            isPersonalReport
              ? { text: `PERSONAL DATA REPORT\n\n`, style: 'header' }
              : report.employments != null
              ? {
                  text: `EMPLOYMENT REPORT FOR YEAR ${report.tax_year}\n\n`,
                  style: 'header'
                }
              : {
                  text: `UNKNOWN REPORT TYPE ${
                    report.tax_year ? `FOR YEAR ${report.tax_year}` : ''
                  }\n\n`,
                  style: 'header'
                },
            isPersonalReport
              ? personalReportTable([report])
              : report.employments != null
              ? report.employments.length > 0
                ? [
                    { text: `GENERAL INFO\n\n`, style: 'subheader1' },
                    employmentsGeneralInfo(report.employments),
                    { text: `\n\n` },
                    { text: `PAYMENTS\n\n`, style: 'subheader1' },
                    generatePaymentTables(report.employments[0].payments)
                  ]
                : `No employments got found by our system`
              : htmlToPdfmake(prettyPrint(report)),
            index !== reports.length - 1
              ? { text: ``, pageBreak: 'after' }
              : undefined
          ];
        })
    ],
    styles: {
      header: {
        fontSize: 24,
        margin: [25, 10, 25, 10],
        bold: true
      },
      subheader1: {
        fontSize: 20,
        bold: true
      },
      subheader2: {
        fontSize: 16,
        margin: [0, 0, 0, 10]
      },
      tableData: {
        fontSize: 14
      },
      counterText: {
        fontSize: 11,
        bold: true,
        margin: [0, 0, 5, 5]
      },
      whatsNextText: {
        fontSize: 11
      }
    },
    ...extraDocumentDefinitionProps
  };

  return documentDefinition;
};

const personalReportTable = (data: any) => {
  return {
    layout: 'lightHorizontalLines',
    style: 'tableData',
    table: {
      body: buildTableBody(data),
      heights: [30, 30, 60, 60, 30]
    }
  };
};

const genericTable = (data: any) => {
  return {
    style: 'tableData',
    table: {
      body: buildTableBody(data),
      heights: 'auto'
    }
  };
};

const employmentsGeneralInfo = (data: any) => {
  return {
    layout: 'noBorders',
    style: 'tableData',
    table: {
      body: buildTableBody(data)
    }
  };
};

/**
 * Look for currency values in an object and format them.
 *
 * !! This method mutates the object recursively, so be careful when using it.
 */
function currencyFormatObject(obj: Record<any, any>, parentKey = '') {
  const keys = Object.keys(obj);

  keys.forEach(function (key) {
    const value = obj[key];

    if (
      typeof value === 'number' &&
      (MONEY_FIELDS.includes(key) || MONEY_FIELDS.includes(parentKey))
    ) {
      obj[key] = currencyFormat(value);
    } else if (Array.isArray(value)) {
      value.forEach((item) => {
        currencyFormatObject(item);
      });
    } else if (isPlainObject(value)) {
      currencyFormatObject(obj[key], key);
    }
  });
}

export const currencyFormat = (value: unknown, currency = 'GBP') => {
  if (typeof value !== 'number') return value;

  return `${new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency
  }).format(value)}`;
};

const buildTableBody = (data: any) => {
  const body: any[] = [];

  data.forEach((row: any) => {
    Object.keys(row)
      .filter((row) => row !== 'tax_year')
      .forEach((key) => {
        const value = row[key];

        if (
          !(row[key] instanceof Array) ||
          (row[key] instanceof Array &&
            row[key].length > 0 &&
            typeof row[key][0] === 'string')
        ) {
          body.push([
            { text: `${camelCaseToFirstLetterUpperCase(key)}:`, bold: true },
            value
          ]);
        }
      });
  });

  return body;
};
