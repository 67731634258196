import { routes } from '@/routes';
import { cs } from '@/utils/string';
import { Link } from 'react-router-dom';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';

export const Breadcrumb = () => {
  const breadcrumbs = useReactRouterBreadcrumbs(routes as any);

  return (
    <nav className="flex py-4 container" aria-label="Breadcrumb">
      <ol
        role="list"
        className="flex space-x-4 rounded-md bg-white items-center px-6 shadow min-h-[44px]">
        {breadcrumbs?.map(({ breadcrumb, key: href }, index) => {
          const isCurrentPage = index === breadcrumbs.length - 1;

          return (
            <li key={href}>
              {index > 0 ? (
                <svg
                  className="inline-block mr-4 w-6 flex-shrink-0 text-gray-200"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true">
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
              ) : null}

              <Link
                to={href}
                className={cs(
                  'text-sm',
                  isCurrentPage
                    ? 'font-bold text-indigo-500 hover:text-indigo-700'
                    : 'font-medium text-gray-500 hover:text-gray-700'
                )}
                aria-current={isCurrentPage ? 'page' : undefined}>
                {breadcrumb}
              </Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
